<script setup>

</script>

<template>
  <div class="container">
    <div class="cardbox">
      <a href="mailto:info@dairamon.com"><img alt='Dairamon' src="/images/logo.png"></a>
    </div>
  </div>
</template>

<style scoped lang="scss">

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

</style>
